.card{
    margin-bottom: 0.7rem;
    padding: 0;
}
.product-card{
    margin-bottom: 0.7rem;
    padding: 0;
}

.product-title{
    padding: 0;
}
