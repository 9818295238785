.disable-dbl-tap-zoom {
    touch-action: manipulation;
}

.basketImage { grid-area: image;}
.productName {
    grid-area: name;
    font-size: 1.2em;
}
.basketItemInfo { grid-area: info;}
.basketMinus { grid-area: take; max-width: 30px; }
.itemValue { grid-area: value; max-width: 30px; }
.basketAdd { grid-area: add; max-width: 30px; }

.basket-grid-container {
    display: grid;
    grid-template-areas:
    'image image name name info take value add';
    grid-gap: 0;
    margin-bottom: 15px;
}

.product-controls{
    border-radius: 10px 10px 10px 10px;
}

.product-product-controls{
    border-radius: 10px 10px 10px 10px;
}

.terms-scroll{
    margin:4px 4px;
    padding:4px;
    width: 100%;
    height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align:justify;
}

.basket-image{
    width: 44px;
    height: auto;
    border-radius: 10px 10px 10px 10px;
}

.basket-product-buttons {
    background-color: #7DDF64;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    padding: 3px;
    border: none;
    border-radius: 7px 7px 7px 7px;
    float: right;
}
.basket-product-value {
    text-align: center;
    background-color: #f1efef;
    border-radius: 5px 5px 5px 5px;
    padding: 3px;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    border: none;
}

/* If you want text inside of the container */
.basket-product-buttons h2{
    vertical-align: middle;
    padding-top: 0;
    font-size: 3em;
    line-height: 3rem;
}

.basket-item-price{
    font-size: 0.7em;
}

.auth-items{
    font-size: 0.8em;
    line-height: 0.7em;
}

.Left { grid-area: left; width: 100%}
.Right { grid-area: right; width: 100%}

.signup-grid-container {
    display: grid;
    grid-template-areas:
    ' left left left right right';
}

.user-details{
    line-height: 15px;
}
.user-warning{
    color: #ea3c53;
    text-align: center;
    font-size: 0.8em;
    line-height: 0.7em;
}

.insufficient-warning{
    color: #ea3c53;
    text-align: left;
    padding-top: 10px;
    font-size: 0.8em;
    line-height: 1.4em;
    width: 100%;
}

.user-success{
    color: #2cc415;
    text-align: center;
    font-size: 0.8em;
    line-height: 0.7em;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
