.row{
    margin-top: 2rem;
}


#floater {
    position: fixed;
    border-radius: 5px 5px 5px 5px;
    padding: 10px;
    top: 60px;
    right: 20px;
    z-index: 9999;
    width: auto;
    height: auto;
}
#color-selector{
    width:60%;
    float:left;
    height:auto;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.home-triangle{
    overflow-x: hidden;
    width: 100%;
    border-bottom: 600px solid black;
    border-right: 1920px solid transparent;
    height: 800px;
    position:fixed;
}

.home-image{
    height: auto;
    width: 100%;
}


.home-background{
    overflow-x: hidden;
    width: 100%;
    background-image: url('https://c4rt-photos.s3.eu-west-2.amazonaws.com/6a8b8c80-6d25-11eb-83dc-0abd24589c41.jpg');
}
