.bacs-input{
    width: 100%;
}

.customer-postcode{
    float: left;
}
.customer-country{
    float: right;
    margin-top: 0;
}

.needed-placeholder::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #dc3545;
}
::-moz-placeholder { /* Firefox 19+ */
    color: #dc3545;
}
:-ms-input-placeholder { /* IE 10+ */
    color: #dc3545;
}
:-moz-placeholder { /* Firefox 18- */
    color: #dc3545;
}
