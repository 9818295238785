.help-bubble .help-bubble-outer-dot {
    margin: 1px;
    display: block;
    text-align: center;
    opacity: 1;
    background-color: rgba(68,219,53,0.4);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: help-bubble-pulse 1.5s linear infinite;
}

.help-bubble {
    float: left;
    display: block;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    left: 25px;
    top: 15px;
}

.help-bubble::after {
    content: "";
    background-color: #44DB35;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    display: block;
    top: 1px;
    left: 1px;
}

.help-bubble .help-bubble-inner-dot {
    background-position: absolute;
    display: block;
    text-align: center;
    opacity: 1;
    background-color: rgba(68,219,53,0.4);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    -webkit-animation: help-bubble-pulse 1.5s linear infinite;
    -moz-animation: help-bubble-pulse 1.5s linear infinite;
    -o-animation: help-bubble-pulse 1.5s linear infinite;
    animation: help-bubble-pulse 1.5s linear infinite;
}

.help-bubble .help-bubble-inner-dot:after {
    content: "";
    background-position: absolute;
    display: block;
    text-align: center;
    opacity: 1;
    background-color: rgba(68,219,53,0.4);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    -webkit-animation: help-bubble-pulse 1.5s linear infinite;
    -moz-animation: help-bubble-pulse 1.5s linear infinite;
    -o-animation: help-bubble-pulse 1.5s linear infinite;
    animation: help-bubble-pulse 1.5s linear infinite;
}

@keyframes help-bubble-pulse{
    0% {
        transform: scale(1);
        opacity: .75;
    }
    25% {
        transform:scale(1);
        opacity:.75;
    }
    100% {
        transform:scale(2);
        opacity:0
    }
}

.cancel-bubble .cancel-bubble-outer-dot {
    margin: 1px;
    display: block;
    text-align: center;
    opacity: 1;
    background-color: rgba(175,38,52,0.4);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: cancel-bubble-pulse 1.5s linear infinite;
}

.cancel-bubble {
    float: left;
    display: block;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    left: 25px;
    top: 15px;
}

.cancel-bubble::after {
    content: "";
    background-color: #af2634;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    display: block;
    top: 1px;
    left: 1px;
}

.cancel-bubble .cancel-bubble-inner-dot {
    background-position: absolute;
    display: block;
    text-align: center;
    opacity: 1;
    background-color: rgba(175,38,52,0.4);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    -webkit-animation: cancel-bubble-pulse 1.5s linear infinite;
    -moz-animation: cancel-bubble-pulse 1.5s linear infinite;
    -o-animation: cancel-bubble-pulse 1.5s linear infinite;
    animation: cancel-bubble-pulse 1.5s linear infinite;
}

.cancel-bubble .cancel-bubble-inner-dot:after {
    content: "";
    background-position: absolute;
    display: block;
    text-align: center;
    opacity: 1;
    background-color: rgba(175,38,52,0.4);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    -webkit-animation: cancel-bubble-pulse 1.5s linear infinite;
    -moz-animation: cancel-bubble-pulse 1.5s linear infinite;
    -o-animation: cancel-bubble-pulse 1.5s linear infinite;
    animation: cancel-bubble-pulse 1.5s linear infinite;
}

@keyframes cancel-bubble-pulse{
    0% {
        transform: scale(1.2);
        opacity: .75;
    }
    25% {
        transform:scale(1.2);
        opacity:.75;
    }
    100% {
        transform:scale(1.2);
        opacity:0.75
    }
}

.dev-bubble .dev-bubble-outer-dot {
    margin: 1px;
    display: block;
    text-align: center;
    opacity: 1;
    background-color: rgba(219,120,53,0.4);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: dev-bubble-pulse 1.5s linear infinite;
}

.dev-bubble {
    float: left;
    display: block;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    left: 25px;
    top: 15px;
}

.dev-bubble::after {
    content: "";
    background-color: #DB7835;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    display: block;
    top: 1px;
    left: 1px;
}

.dev-bubble .dev-bubble-inner-dot {
    background-position: absolute;
    display: block;
    text-align: center;
    opacity: 1;
    background-color: rgba(219,120,53,0.4);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    -webkit-animation: dev-bubble-pulse 1.5s linear infinite;
    -moz-animation: dev-bubble-pulse 1.5s linear infinite;
    -o-animation: dev-bubble-pulse 1.5s linear infinite;
    animation: dev-bubble-pulse 1.5s linear infinite;
}

.dev-bubble .dev-bubble-inner-dot:after {
    content: "";
    background-position: absolute;
    display: block;
    text-align: center;
    opacity: 1;
    background-color: rgba(219,120,53,0.4);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    -webkit-animation: dev-bubble-pulse 1.5s linear infinite;
    -moz-animation: dev-bubble-pulse 1.5s linear infinite;
    -o-animation: dev-bubble-pulse 1.5s linear infinite;
    animation: dev-bubble-pulse 1.5s linear infinite;
}

@keyframes dev-bubble-pulse{
    0% {
        transform: scale(1);
        opacity: .75;
    }
    25% {
        transform:scale(1);
        opacity:.75;
    }
    100% {
        transform:scale(2);
        opacity:0
    }
}
