.Image { grid-area: image; }
.Title { grid-area: title; }
.Info { grid-area: info; }
.Reduce { grid-area: reduce; }
.Display { grid-area: display; }
.Plus { grid-area: plus; }
.Price { grid-area: price; }
.admin-product-container {
    display: grid;
    grid-template-areas:
    'image image image title title title title'
    'image image image title title title title'
    'image image image price  reduce display plus'
    'image image image info  reduce display plus';
    padding: 10px 0 10px 0;
}
.card-body{
    padding: 0;
}

.admin-product-image{
    height:auto;
    width:90px;
    text-align:left;
    border-radius: 10px 10px 10px 10px;
}

.Categories { grid-area: categories; }
.Status { grid-area: status; }
.Delete { grid-area: delete; }
.Index { grid-area: index; }


.status-grid-container {
    display: grid;
    grid-template-areas:
    'index categories categories status status delete';
    grid-gap: 10px;
}

.status-grid-container > div {
    text-align: center;
}

.Name { grid-area: name; font-size: 1.5em; font-weight: bold;}
.Image { grid-area: image; }
.Price { grid-area: price; }

.admin-grid-container {
    display: grid;
    grid-template-areas:
    'image name name name'
    'image price price price';
    grid-gap: 10px;
    padding: 10px;
}

.admin-grid-container > div {
    text-align: center;
}

.product-info-inputs:disabled{
    background: inherit;
    /*width: 50px!important;*/
    /*border: none;*/
}

.product-info-inputs::placeholder{
    color: #4e555b;
}

.product-name-input{
    width: 100%;
    border: none;
}
.product-name-input::placeholder{
    color: #4e555b;
}

.admin-image{
    background: #ccc;
}

.admin-input{
    border: solid 2px #f2f2f2!important;
    border-radius: 5px 5px 5px 5px
}
