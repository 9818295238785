.OrderSelect { grid-area: select; }
.OrderName { grid-area: name; }
.OrderDate { grid-area: date; }
.OrderNumber { grid-area: number; }

.orders-admin-grid-container {
  display: grid;
  grid-template-areas:
    'select number name name name date';
  grid-gap: 0;
}

.OrderType { grid-area: type; }
.OrderPrice {
  grid-area: price;
  width: 100%;
}
.OrderView {
  display: block;
  grid-area: view;
  text-align: right;
}
.OrderFunction {
  display: block;
  grid-area: function;
  text-align: right;
}

.orders-admin-grid-container-two {
  display: grid;
  grid-template-areas:
    'type price view function';
  grid-gap: 10px;
}

.order-row-padding {
  padding-top: 8px;
}

.order-paragraph-padding{
  margin-bottom: 0!important;
}
