.Icon {
    grid-area: icon;
    font-size: 2.6em;
}
.Circle
{
    border-radius: 50%;
    padding-left: 21px;
    padding-top: 9px;
    width: 85px;
    height: 85px;
    background: #0c5460;
    color: white;
    cursor: pointer;
    margin-right: 7px;
}
.OrderName { grid-area: name; }
.Price { grid-area: price; }
.Items { grid-area: items; }
.Options { grid-area: options; }

.order-grid-container {
    display: grid;
    grid-template-areas:

    'icon icon name name price price'
    'icon icon name name price price'
    'icon icon options options items items'
    'icon icon options options items items'
;
    width: 100%;
}

.order-container{
    padding: 7px;
}
.cancel-order{
    float: right;
    font-size: 1.5em;
    color: #dc3545;
    cursor: pointer;
}
.activate-order{
    float: right;
    font-size: 1.4em;
    color: #35dc79;
    cursor: pointer;
}
.nav-view{
    float: right;
    font-size: 1.5em;
    color: white;
    cursor: pointer;
}
.nav-logout{
    padding-top: 0;
    font-size: 1.4em;
    padding-bottom: 0;
    float: right;
    cursor: pointer;
}
.nav-logout-text{
    font-size: 1em!important;
}


.nav-button-right {
    margin-left: 0.8rem;
    margin-bottom: 0.8rem;
    float: right;
}

.item-left{
    float: left;
    margin-left: 20%;
}

.nav-buttons {
    font-size: 1.1em;
    margin-left: 0.1rem;
    margin-bottom: 0.8rem;
    margin-top:7px;
    height: 30px;
    width:30px;
    padding: 0;
}
.left-nav-button {
    font-size: 1.1em;
    margin-bottom: 0.8rem;
    height: 30px;
    margin-top:7px;
    padding: 0;
    width:30px;
}

.item-price{
    font-size: 1.7em;
    font-weight: bold;
    margin-left: 0.2rem;
    float: right;
}
.order-details{
    padding: 0;
    margin:0;
}

.invoice-modal{
    width: 70%;
}

.OrdersNumber { grid-area: number; }
.OrdersType { grid-area: type; }
.OrdersStatus { grid-area: status; }
.OrdersPrice { grid-area: price; }
.OrdersAction { grid-area: action; }
.customer-orders-grid-container {
    display: grid;
    grid-template-areas:
    'number type status price action ';
    padding: 10px;
}
