.user-button {
    padding-top: 0;
    border-radius: 4px 4px 4px 4px;
    font-size: 1em;
    text-align: center;
    min-height: 35px;
    width: auto;
    min-width: 120px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    line-height: 35px;
    vertical-align: middle;
    background-color: transparent;
    display: inline-block;
    margin-left: 10px;
    margin-bottom:10px;
}
.user-small-button{
    min-width: 60px!important;
}
.price-button{
    padding-top: 0;
    border-radius: 4px 4px 4px 4px;
    font-size: 1em;
    text-align: center;
    min-height: 35px;
    width: auto;
    min-width: 120px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    line-height: 35px;
    vertical-align: middle;
    background-color: transparent;
    display: inline-block;
    margin-left: 10px;
    margin-bottom:0px;
}
.edit-button {
    padding-top: 0;
    border-radius: 4px 4px 4px 4px;
    font-size: 1em;
    text-align: center;
    min-height: 30px;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    line-height: 30px;
    vertical-align: middle;
    background-color: transparent;
    display: inline-block;
    margin-left: 15px;
}
.category-edit-button{
    border-radius: 4px 4px 4px 4px;
    text-align: center;
    height: 25px;
    max-width:25px;
    font-size:0.7em;
    padding: 3px;
    cursor: pointer;
    margin-left:10px;
    margin-right: 10px;
    margin-top:5px;
    line-height: 25px;
    vertical-align: middle;
    background-color: transparent;
    display: block;
}

.sidebar-user-category{
    padding-top: 0;
    border-radius: 4px 4px 4px 4px;
    font-size: 1em;
    text-align: center;
    min-height: 35px;
    width:170px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    line-height: 30px;
    vertical-align: middle;
    background-color: transparent;
    display: block;
    margin: 10px 35px 0 10px;
}

.sidebar-category{
    padding-top: 0;
    border-radius: 4px 4px 4px 4px;
    font-size: 1em;
    text-align: center;
    min-height: 35px;
    width:170px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    line-height: 30px;
    vertical-align: middle;
    background-color: transparent;
    display: block;
    margin: 0 auto 10px auto;
}

.email-settings{
    padding-top: 0;
    min-width: 120px;
    border-radius: 4px 4px 4px 4px;
    font-size: 1em;
    text-align: center;
    min-height: 35px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    line-height: 30px;
    vertical-align: middle;
    background-color: transparent;
    display: inline-block;
    margin: 0 auto 10px auto;
}

.sidebar-button {
    margin-top: 0;
    margin-bottom: 10px;
    border-radius: 4px 4px 4px 4px;
    font-size: 1em;
    text-align: center;
    min-height: 35px;
    width: auto;
    min-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    line-height: 30px;
    vertical-align: middle;
    background-color: transparent;
    display: inline-block;
    margin-left: 15px;
}

.nav-button {
    padding-top: 0;
    min-width: 120px;
    border-radius: 4px 4px 4px 4px;
    font-size: 1em;
    text-align: center;
    min-height: 35px;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    line-height: 30px;
    vertical-align: middle;
    background-color: transparent;
    display: inline-block;
    margin-left: 15px;
}

.ticket-button {
    padding-top: 0;
    min-width: 120px;
    border-radius: 4px 4px 4px 4px;
    font-size: 1em;
    text-align: center;
    min-height: 35px;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    line-height: 30px;
    vertical-align: middle;
    background-color: transparent;
    display: inline-block;
    margin-right: 10px;
}

.ticket-send-button {
    padding-top: 0;
    min-width: 120px;
    border-radius: 4px 4px 4px 4px;
    font-size: 1em;
    text-align: center;
    min-height: 35px;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    line-height: 30px;
    vertical-align: middle;
    background-color: transparent;
    display: inline-block;
}

.nav-user-button {
    padding-top: 0;
    border-radius: 4px 4px 4px 4px;
    font-size: 1.1em;
    text-align: center;
    min-height: 35px;
    width: auto;
    padding-left: 10px;
    padding-right: 15px;
    cursor: pointer;
    line-height: 30px;
    vertical-align: middle;
    background-color: transparent;
    display: inline-block;
    margin-left: 15px;
}

.user-sidebar {
    width: 170px!important;
    margin-left:auto!important;
    margin-right: auto!important;
}

.pink{
    border: 2px solid #ff79c6;
    color: #ff79c6;
}

.pink:hover {
    background-color: transparent;
}

.red{
    border: 2px solid #f34e49;
    color: #f34e49;
}

.red:hover {
    background-color: transparent;
}

.grey{
    border: 2px solid #878686;
    color: #878686;
}

.grey:hover {
    background-color: transparent;
}

.dark-pink{
    border: 2px solid #f061b3;
    color: #f061b3;
}

.dark-pink:hover {
    background-color: transparent;
}

.dark-blue{
    border: 2px solid #282a36;
    color: #282a36;
}

.dark-blue:hover {
    background-color: transparent;
}

.dark-purple{
    border: 2px solid #826ae3;
    color: #826ae3;
}

.dark-purple:hover {
    background-color: transparent;
}

.orange{
    border: 2px solid #FE9470;
    color: #FE9470;
}
.orange:hover {
    border: 2px solid white;
    background-color: #FE9470;
    color: white;
}

.green{
    border: 2px solid #70fe94;
    color: #70fe94;
}
.green:hover {
    border: 2px solid white;
    background-color: transparent;
    color: white;
}

.purple{
    border: 2px solid #a692f8;
    color: #a692f8;
}

.purple:hover {
    border: 2px solid #ff79c6;
    background-color: transparent;
    color: #ff79c6;
}

.basket{
    line-height: 35px;
    color: white;
    font-size: 1.7em;
    width: 140px;
    text-align: center;
}

.white{
    border: 2px solid white;
    color: white;
}

.white:hover {
    border: 2px solid #ff79c6;
    background-color: transparent;
    color: #ff79c6;
}

.blue{
    border: 2px solid #48a7f4;
    color: #48a7f4;
}

.blue:hover {
    border: 2px solid #a692f8;
    background-color: transparent;
    color: #a692f8;
}

