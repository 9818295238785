.nav-controls{
    margin-left: 1rem;
}
.nav-rocket{
    float: right;
}
.nav-basket{
    float: right;
}
.pay-button{
    float: right;
    width: 150px;
    font-size: 1.5em;
    margin-top: 0 ;
    margin-bottom: 0;
    line-height: 24px;
}

.pay-button-color{
    background: #20c997;
    border: solid 1px #20c997;
}

.pay-button-color:hover{
    background: #20c997;
    border: solid 1px #20c997;
}

.main-navigation__drawer-nav {
    height: 100%;
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    z-index: 10;
}
