.launch-button {
    padding-top: 0;
    border-radius: 4px 4px 4px 4px;
    font-size: 1em;
    text-align: center;
    min-height: 45px;
    width: 100%;
    cursor: pointer;
    line-height: 45px;
    vertical-align: middle;
    background-color: transparent;
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 0;
    margin-top: 0;
}
