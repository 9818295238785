.side-drawer {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    height: 100vh;
    width: 70%;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.main-navigation__drawer-nav {
    height: 100%;
}

@media only screen and (min-width: 990px) {
    .hide-nav-toggle {
        display: none;
    }
}
