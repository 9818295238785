.Image { grid-area: image;  }
.Title { grid-area: title; }
.Info { grid-area: info; }
.Reduce { grid-area: reduce; }
.Display { grid-area: display; }
.Plus { grid-area: plus;  }
.product-container {
    display: grid;
    padding: 10px;
    grid-template-areas:
    'image image image image title title title title title title title title'
    'image image image image title title title title title title title title'
    'image image image image price price price price price reduce display plus'
    'image image image image price price price price price reduce display plus';
}
.card-body{
    padding-left: 0;
    padding-right: 0;
}

.Qty { grid-area: qty;  }
.Price { grid-area: price; }
.Reduce { grid-area: reduce; }
.Display { grid-area: display; }
.Plus { grid-area: plus;  }
.pricing-container {
    display: grid;
    padding: 10px;
    grid-template-areas:
    'qty qty qty qty qty price price price price reduce display plus'
    'qty qty qty qty qty price price price price reduce display plus';
}
.card-body{
    padding-left: 0;
    padding-right: 0;
}

.product-image{
    height:auto;
    width:100px;
    border-radius: 10px 10px 10px 10px;
    margin-right: 10px;
}

.product-image-manage{
    height:auto;
    width:30px;
    border-radius: 10px 10px 10px 10px;
    margin-right: 10px;
}

.product-buttons {
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    padding: 0;
    border: none;
}

.option-product-buttons {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    padding: 0;
    border: none;
}

/* If you want text inside of the container */
.product-buttons h2{
    vertical-align: middle;
    padding-top: 0;
    font-size: 3em;
    line-height: 3rem;
}
