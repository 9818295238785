body {
  background-color: #ccc;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.amount-display {
  display: table;
  text-align: center;
  width: 3rem;
  height: 3rem;
}

.options-amount-display {
  display: table;
  text-align: center;
  width: 2rem;
  height: 2rem;
}

/* If you want text inside of the container */
.amount-display h2{

}
 .button-container{
   display: table;
   text-align: center;
 }

.product-controls{
  border-radius: 10px 10px 10px 10px;
}

Button:focus {
  outline: none!important;
}

.form-control{
  margin-top: 10px;
}

/*.infos{*/
/*  border: solid 2px #bc92da;*/
/*  color: #bc92da*/
/*}*/
/*.infos:active{*/
/*  border: solid 2px #bc92da;*/
/*  color: #bc92da*/
/*}*/
/*.infos:visited{*/
/*  border: solid 2px #bc92da;*/
/*  color: #bc92da*/
/*}*/
/*.infos:hover{*/
/*  background-color: transparent;*/
/*  color: #ff79c6;*/
/*  border: solid 2px #ff79c6;*/
/*}*/

/*.lightR{*/
/*  border: solid 2px #ff79c6;*/
/*  color: #ff79c6*/
/*}*/
/*.lightR:active{*/
/*  border: solid 2px #ff79c6;*/
/*  color: #ff79c6*/
/*}*/
/*.lightR:hover{*/
/*  background-color: transparent;*/
/*  color: #ff79c6;*/
/*  border: solid 2px #ff79c6;*/
/*}*/
