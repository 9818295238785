.support-select{
    margin-top: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    height: auto;
    width: 160px;
}

.user-support-row{
    border:1px solid #ccc;
    padding: 20px 0 0 0;
    background-color: white;
    border-radius: 7px 7px 7px 7px;
    margin-bottom: 0;
    margin-top:7px;
    height:auto;
}
