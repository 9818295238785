.category-navigation{
    margin-bottom: 1.5rem;
    padding-top: 0.8rem;
}

.nav-button {
    margin-left: 0.8rem;
    margin-bottom: 0.8rem;
    min-width: 130px;
}

.admin-nav{
    width: 100%;
}

.category-menu-inputs{
    margin: auto 0.1rem;
    background: inherit;
    height: 25px;
    border-radius: 5px 5px 5px 5px;
    padding-left: 0;
    border: none;
    color: white;
    text-align: center;
    width: 120px;

}

.cart-button{
    width: 150px;
    font-size: 1.5em;
    margin-top: 0 ;
    margin-bottom: 0;
    line-height: 24px;
}

.cart-button-color{
    background: #20c997;
    border: solid 1px #20c997;
}

.cart-button-color:hover{
    background: #20c997;
    border: solid 1px #20c997;
}

@media only screen and (max-width: 990px) {
    .admin-nav-toggle {
        display: none;
    }
}
