.btn-primary {
    color: #212529;
    background-color: #bd93f9;
    border-color: #bd93f9
}

.btn-primary:hover {
    color: #212529;
    background-color: #bd93f9;
    border-color: #bd93f9
}

.btn-primary:focus,
.btn-primary.focus {
    box-shadow: 0 0 0 .2rem rgba(189, 147, 249, 0.5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #212529;
    background-color: #bd93f9;
    border-color: #a474e9
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: #212529;
    background-color: #bd93f9;
    border-color: #a474e9
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(91, 194, 194, 0.5)
}

.btn-outline-primary {
    color: #bd93f9;
    background-color: transparent;
    background-image: none;
    border-color: #bd93f9
}

.btn-outline-primary:hover {
    color: #222;
    background-color: #bd93f9 ;
    border-color: #bd93f9
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 .2rem rgba(189, 147, 249, 0.5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #bd93f9;
    background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #bd93f9 ;
    border-color: #bd93f9
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(119, 204, 204, 0.5)
}
